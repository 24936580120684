import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./Site.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import CustomRadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import SearchOrganisation from "../../UI/SearchOrganisation/SearchOrganisation";
import ImageEdit from "../../utils/ImageEdit/ImageEdit";
import CheckBoxes from "../../UI/CheckBox/CheckBoxes";
import SelectBox from "./../../UI/FormGroup/SelectBox/SelectBox";

export default function AddSite() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [hpio, setHpio] = useState(null);
  const [hpioError, setHpioError] = useState(null);
  // const [mimsIntegrated, setMimsIntegrated] = useState(false);
  const [backUrl, setBackUrl] = useState(null);
  const [backUrlError, setBackUrlError] = useState(null);
  const [frontUrl, setFrontUrl] = useState(null);
  const [frontUrlError, setFrontUrlError] = useState(null);
  const [abn, setAbn] = useState(null);
  const [abnError, setAbnError] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [businessNameError, setBusinessNameError] = useState(null);
  const [addressLine1, setAddressLine1] = useState(null);
  const [addressLine2, setAddressLine2] = useState(null);
  const [addressOneError, setAddressOneError] = useState(null);
  const [suburb, setCity] = useState(null);
  const [suburbError, setCityError] = useState(null);
  const [phone, setphone] = useState(null);
  const [phoneError, setphoneError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [postcodeError, setPostcodeError] = useState(null);
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [fax, setFax] = useState(null);
  const [faxError, setFaxError] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [templateLogo, setTemplateLogo] = useState(null);
  const [headerLogoError, setHeaderLogoError] = useState(null);
  const [templateLogoError, setTemplateLogoError] = useState(null);
  const [deleteLogo, setDeleteLogo] = useState(null);
  const [headerDeleted, setHeaderDeleted] = useState(false);
  const [templateDeleted, setTemplateDeleted] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const [organisationError, setOrganisationError] = useState(null);
  const [banner, setBanner] = useState(null);
  const [medicineType, setMedicineType] = useState(["Allopathic"]);
  const [medicineTypeError, setMedicineTypeError] = useState(null);
  const [ipAdmission, setIpAdmission] = useState(false);
  const [interval, setInterval] = useState(null);
  const [intervalError, setIntervalError] = useState(null);

  const [fileUploadError, setFileUploadError] = useState(null);

  const [imageURL, setimageURL] = useState(null);

  const [data, setData] = useState(null);

  const nameHandler = (e) => {
    setNameError(null);
    setName(e.target.value);
  };

  const nameValidation = () => {
    if (!name) {
      setNameError("Required");
      return false;
    }
    return true;
  };

  const locationHandler = (e) => {
    setLocationError(null);
    setLocationId(e.target.value);
  };

  const locationValidation = (e) => {
    if (!name) {
      setLocationError("Required");
      return false;
    }
    return true;
  };

  const hpioHandler = (e) => {
    setHpioError(null);
    setHpio(e.target.value);
  };

  const hpioValidation = (e) => {
    if (!name) {
      setHpioError("Required");
      return false;
    }
    return true;
  };

  const abnHandler = (e) => {
    setAbnError(null);
    setAbn(e.target.value);
  };

  const abnValidation = (e) => {
    if (!abn) {
      setAbnError("Required");
      return false;
    }
    return true;
  };

  const backUrlHandler = (e) => {
    setBackUrlError(null);
    setBackUrl(e.target.value);
  };

  const backUrlValidation = (e) => {
    if (!backUrl) {
      setBackUrlError("Required");
      return false;
    }
    return true;
  };

  const frontUrlHandler = (e) => {
    setFrontUrlError(null);
    setFrontUrl(e.target.value);
  };

  const frontUrlValidation = (e) => {
    if (!frontUrl) {
      setFrontUrlError("Required");
    }
    return true;
  };

  const businessNameHandler = (e) => {
    setBusinessNameError(null);
    setBusinessName(e.target.value);
  };

  const businessNameValidation = () => {
    if (!businessName) {
      setBusinessNameError("Required");
      return false;
    }
    return true;
  };

  const addressLine1Handler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setAddressOneError(null);
    setAddressLine1(value);
  };

  const addressOneValidation = () => {
    if (!addressLine1) {
      setAddressOneError("Required");
      return false;
    }
    return true;
  };

  const addressLine2Handler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setAddressLine2(value);
  };

  const suburbHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setCityError(null);
    setCity(value);
  };

  const suburbValidation = () => {
    if (!suburb) {
      setCityError("Required");
      return false;
    }
    return true;
  };

  function isValidInput(input) {
    // Regular expression pattern to match allowed characters: 0-9, +, and white space
    const pattern = /^[0-9+\s]+$/;

    // Test if the input matches the pattern
    if (!input) {
      return true;
    }
    return pattern.test(input);
  }

  const phoneHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setphoneError(null);
    let isValid = isValidInput(value);
    if (!isValid) {
      return;
    }
    setphone(e.target.value);
  };

  const phoneValidation = () => {
    if (!phone) {
      setphoneError("Required");
      return false;
    }
    return true;
  };

  const emailHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setEmailError(null);
    setEmail(value);
  };

  const emailValidation = () => {
    if (!email) {
      setEmailError("Required");
      return false;
    }
    if (!isEmail(email) && email && email.length > 0) {
      setEmailError("Invalid email address");
      return false;
    }
    return true;
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const postcodeHandler = (e) => {
    setPostcodeError(null);
    setPostcode(e.target.value);
  };

  const postCodeValidation = () => {
    if (!postcode) {
      setPostcodeError("Required");
      return false;
    }
    if (postcode && postcode.length < 4) {
      setPostcodeError("Postcode should be 4 digits");
      return false;
    }
    return true;
  };

  const stateHandler = (e) => {
    setStateError(null);
    setState(e.target.value);
  };

  const stateValidation = () => {
    if (!state) {
      setStateError("Required");
      return false;
    }
    return true;
  };

  const countryHandler = (e) => {
    setCountryError(null);
    setCountry(e.target.value);
  };

  const countryValidation = () => {
    if (!country) {
      setCountryError("Required");
      return false;
    }
    return true;
  };

  const faxHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setFaxError(null);
    setFax(value);
  };

  const faxValidation = () => {
    if (!fax) {
      setFaxError("Required");
      return false;
    }
    return true;
  };

  const bannerHandler = (img) => {
    setBanner(img);
  };

  async function postData() {
    const formData = new FormData();
    formData.append("org_id", organisation?.id);
    formData.append("site_name", name);
    // formData.append("location_id", location);
    formData.append("gstin_number", hpio);
    formData.append("backend_url", backUrl);
    formData.append("frontend_url", frontUrl);
    formData.append("cin_number", abn);
    formData.append("business_name", businessName);
    formData.append("address_line_one", addressLine1);
    formData.append("address_line_two", addressLine2);
    formData.append("suburb", suburb);
    formData.append("work_phone", phone);
    formData.append("work_email", email);
    formData.append("post_code", postcode);
    formData.append("state", state);
    formData.append("fax", fax);
    formData.append("country", country);
    formData.append("folder_name", `${name}/logo`);
    formData.append("is_allopathic", medicineType?.includes("Allopathic"));
    formData.append("is_ayurvedha", medicineType?.includes("Ayurvedha"));
    formData.append("ip_admission", ipAdmission);
    formData.append("appointment_interval", interval);

    if (headerLogo) {
      formData.append("header_logo", headerLogo);
    }

    if (templateLogo) {
      formData.append("template_logo", templateLogo);
    }

    if (banner) {
      formData.append("banner", banner);
    }

    const result = await axios
      .post(
        `${backendUrl}/api/site`,
        formData,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/sites-management`, {
          state: {
            showAlert: "Site details has been created successfully",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        if (e?.response?.data?.message.includes("already exist")) {
          setError(e?.response?.data?.message);
          return;
        }
        setError("Something went wrong. Please try again");
      });

    setimageURL(result.imagePath);

    return result.data;
  }

  const headerLogoHandler = (event) => {
    setHeaderLogoError("");
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);

    const logo = event.target.files[0];
    setHeaderLogo(logo);
  };

  const templateLogoHandler = (event) => {
    setTemplateLogoError("");
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);

    const logo = event.target.files[0];
    setTemplateLogo(logo);
  };

  const fileUploadValidation = (event, file) => {
    // var allowedExtensions =/(\.jpg|\.jpeg|\.png)$/i;
    let file_size = event.target.files[0].size;

    if (file_size > 1020157) {
      if (file === "HEADER") {
        setHeaderLogoError("File size exceeds 1 MB");
      }
      if (file === "TEMPLATE") {
        setTemplateLogoError("File size exceeds 1 MB");
      }
    }
  };

  const submitValidation = () => {
    let proceed = true;

    proceed = proceed && nameValidation();
    // proceed = proceed && locationValidation();
    proceed = proceed && backUrlValidation();
    proceed = proceed && frontUrlValidation();
    proceed = proceed && businessNameValidation();
    proceed = proceed && addressOneValidation();
    proceed = proceed && suburbValidation();
    proceed = proceed && phoneValidation();
    proceed = proceed && emailValidation();
    proceed = proceed && postCodeValidation();
    proceed = proceed && stateValidation();
    proceed = proceed && countryValidation();
    proceed = proceed && medicineTypeValidation();
    proceed = proceed && intervalValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      nameValidation();
      // locationValidation();
      backUrlValidation();
      frontUrlValidation();
      businessNameValidation();
      addressOneValidation();
      suburbValidation();
      phoneValidation();
      emailValidation();
      postCodeValidation();
      stateValidation();
      countryValidation();
      medicineTypeValidation();
      intervalValidation();

      return false;
    }
  };

  const intervalHandler = (e) => {
    setIntervalError(null);
    setInterval(e.target.value);
  };

  const intervalValidation = () => {
    if (!interval) {
      setIntervalError("Required");
      return false;
    }
    return true;
  };

  const intervalOptions = [
    {
      label: "10 minutes",
      value: 10,
    },
    {
      label: "15 minutes",
      value: 15,
    },
    {
      label: "20 minutes",
      value: 20,
    },
  ];

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    if (headerLogoError || templateLogoError) {
      setLoader(false);
      fileUploadValidation(null, "HEADER");
      fileUploadValidation(null, "TEMPLATE");
      return;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/sites-management",
      iconName: "bi:building",
      name: "Sites Management",
    },
    {
      link: "#",
      iconName: "el:plus-sign",
      name: "Add Site",
    },
  ];

  const handleDelete = (logo) => {
    setDeleteLogo(logo);
  };

  const confirmDelete = async () => {
    if (deleteLogo === "template") {
      setTemplateDeleted(true);
    } else {
      setHeaderDeleted(true);
    }
    closeDelete();
  };

  const closeDelete = () => {
    setDeleteLogo();
  };

  const clearHeaderInput = () => {
    setHeaderLogoError();
    setHeaderLogo();
  };
  const clearTemplateInput = () => {
    setTemplateLogoError();
    setTemplateLogo();
  };

  const organisationHandler = (value) => {
    if (!value) {
      setOrganisation(null);
      return;
    }
    setOrganisationError();
    if (value) {
    }
    setOrganisation({ ...value });
  };

  const organisationValidation = (msg) => {
    if (msg) {
      setOrganisationError(msg);
      return;
    }
    if (!organisation) {
      setOrganisationError("Required");
    }
  };

  const selectedOrganisation = (value) => {
    setOrganisation(value);
  };

  const mimsIntegratedOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const medicineOptions = [
    {
      label: "Allopathic",
      value: "Allopathic",
    },
    {
      label: "Ayurvedha",
      value: "Ayurvedha",
    },
  ];

  const medicineTypeHandler = (e) => {
    setMedicineTypeError(null);
    let sts = e.target.value;
    let arr;
    if (medicineType) {
      arr = [...medicineType, sts];
    } else {
      arr = [sts];
    }
    if (medicineType?.includes(sts)) {
      arr = arr.filter((s) => s !== sts);
    }
    if (!arr.length) {
      setMedicineTypeError("Required");
    }
    setMedicineType(arr);
  };

  const medicineTypeValidation = () => {
    if (!medicineType?.length) {
      setMedicineTypeError("Required");
      return false;
    }
    return true;
  };

  const ipAdmissionHandler = (e) => {
    setIpAdmission((prev) => !prev);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}
      <CautionAlert
        severity="error"
        open={deleteLogo}
        content={`Are you sure you want to delete ${deleteLogo} logo?`}
        onCloseActionB={confirmDelete}
        onCloseActionA={closeDelete}
        okText="Delete"
        cancelText="Cancel"
      />

      <PageHeader left="Add Site" />
      <Loader open={loader} />

      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            {/* First Column */}
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <SearchOrganisation
                xsLabel={4}
                xsInside={8}
                organisationValidation={organisationValidation}
                organisationError={organisationError}
                selectedOrganisation={selectedOrganisation}
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Site Name"
                onChangeAction={nameHandler}
                onBlurAction={nameValidation}
                value={name}
                error={nameError}
                placeholder="State"
                required
              />
              {/* <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Location Id"
                onChangeAction={locationHandler}
                onBlurAction={locationValidation}
                error={locationError}
                value={location}
                required
              /> */}
              <TextBox
                xsLabel={4}
                xsInside={8}
                label="CIN"
                onChangeAction={abnHandler}
                value={abn}
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                label="GSTIN"
                onChangeAction={hpioHandler}
                value={hpio}
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Business Name"
                onChangeAction={businessNameHandler}
                value={businessName}
                onBlurAction={businessNameValidation}
                error={businessNameError}
                placeholder="Business Name"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Address line 1"
                onChangeAction={addressLine1Handler}
                value={addressLine1}
                onBlurAction={addressOneValidation}
                error={addressOneError}
                placeholder="Address line 1"
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Address line 2"
                onChangeAction={addressLine2Handler}
                value={addressLine2}
                placeholder="Address line 2"
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="City "
                onChangeAction={suburbHandler}
                value={suburb}
                onBlurAction={suburbValidation}
                error={suburbError}
                placeholder="City"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Post Code"
                onChangeAction={postcodeHandler}
                value={postcode}
                onBlurAction={postCodeValidation}
                error={postcodeError}
                placeholder="Post Code"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="State"
                onChangeAction={stateHandler}
                value={state}
                onBlurAction={stateValidation}
                error={stateError}
                placeholder="State"
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Country"
                onChangeAction={countryHandler}
                value={country}
                onBlurAction={countryValidation}
                error={countryError}
                placeholder="Country"
                required
              />
              <Grid container spacing={2} mb={1}>
                <Grid item xs={4} className={`LabelBox`}>
                  Banner
                </Grid>
                <Grid item xs={8}>
                  <ImageEdit
                    image={banner}
                    dataHandler={bannerHandler}
                    ratio={41 / 59}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              className={classes.Max}
              sx={{
                marginLeft: widthIs1630 ? "" : "80px",
                maxWidth: widthIs14 ? "" : "590px !important",
              }}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Phone Number"
                onChangeAction={phoneHandler}
                value={phone}
                onBlurAction={phoneValidation}
                error={phoneError}
                placeholder="Phone"
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Email"
                onChangeAction={emailHandler}
                value={email}
                onBlurAction={emailValidation}
                error={emailError}
                placeholder="Email"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Fax Number"
                onChangeAction={faxHandler}
                value={fax}
              />

              <SelectBox
                xsLabel={4}
                xsInside={8}
                type="number"
                numberOnly={true}
                options={intervalOptions}
                label="Appointment Slot Interval"
                onChangeAction={intervalHandler}
                value={interval}
                onBlurAction={intervalValidation}
                error={intervalError}
                placeholder="Fax"
                required
              />
              <CheckBoxes
                onChangeAction={(e) => medicineTypeHandler(e)}
                data={medicineOptions}
                label="Medicine Type"
                status={medicineType}
                error={medicineTypeError}
                required
              />

              <Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={4} className={`LabelBox`}></Grid>
                  <Grid item xs={8}>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "14px",
                          },
                        }}
                        control={
                          <Checkbox
                            style={{
                              color: "#615586",
                            }}
                            checked={ipAdmission === true}
                            value={ipAdmission === true}
                            onChange={ipAdmissionHandler}
                          />
                        }
                        label={"IP Admission"}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Fragment>

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Backend URL"
                onChangeAction={backUrlHandler}
                value={backUrl}
                onBlurAction={backUrlValidation}
                error={backUrlError}
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Frontend URL"
                onChangeAction={frontUrlHandler}
                value={frontUrl}
                onBlurAction={frontUrlValidation}
                error={frontUrlError}
                required
              />

              {data?.header_logo && (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={4} className={`LabelBox`}></Grid>

                  <Grid item xs={4}>
                    <div
                      style={{
                        background: "#56438E",
                        padding: "10px",
                        height: "100px",
                        width: "150px",
                        borderRadius: "15px",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${data?.header_logo})`,
                          height: "100%",
                          width: "100%",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Button
                      startIcon={<Icon icon="fluent:delete-24-regular" />}
                      text="Delete"
                      onClickAction={() => handleDelete("header")}
                      disabled={headerDeleted}
                    />
                  </Grid>
                </Grid>
              )}

              <UploadButton
                xsLabel={4}
                xsInside={8}
                label="Header Logo"
                onBlurAction={(e) => fileUploadValidation(e, "HEADER")}
                onChangeAction={headerLogoHandler}
                error={headerLogoError}
                clear={clearHeaderInput}
              />
              {data?.template_logo && (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={4} className={`LabelBox`}></Grid>

                  <Grid item xs={4}>
                    <div
                      style={{
                        backgroundImage: `url(${data?.template_logo})`,
                        height: "100px",
                        width: "150px",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Button
                      startIcon={<Icon icon="fluent:delete-24-regular" />}
                      text="Delete"
                      onClickAction={() => handleDelete("template")}
                      disabled={templateDeleted}
                    />
                  </Grid>
                </Grid>
              )}

              <UploadButton
                xsLabel={4}
                xsInside={8}
                label="Template Logo"
                onBlurAction={(e) => fileUploadValidation(e, "TEMPLATE")}
                onChangeAction={templateLogoHandler}
                error={templateLogoError}
                clear={clearTemplateInput}
                logo={true}
              />

              {/* {images.map((image) => (
                  <div key={image}>
                    <img
                      src={"http://axon.medipapel.com:8080/" + image}
                      alt="img"
                    ></img>
                  </div>
                ))} */}
            </Grid>
            {/* First Column */}
            {/* <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <Grid container spacing={2} mb={1}>
                <Grid item xs={4} className={`LabelBox`}>
                  Banner
                </Grid>
                <Grid item xs={8}>
                  <ImageEdit
                    image={banner}
                    dataHandler={bannerHandler}
                    ratio={41 / 59}
                  />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Submit"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
