import { Box } from "@mui/system";
import React, { useState } from "react";
import { Grid, TextField, Typography, Link, Button } from "@mui/material";
import { CssBaseline } from "@material-ui/core";
import Image from "./../.././assets/Images/forgotpss-side.png";
import { ThemeProvider } from "react-bootstrap";
import logo from "./../.././assets/Images/DarkLogo.png";
import Theme from "../Theme/Theme";
import Alert from "@mui/material/Alert";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth";
import CustomLoader from "../UI/Loader/Loader";

const validationSchema = yup.object({
  username: yup.string("Username").required("Required"),
});

const Forgotpassword = () => {
  let navigate = useNavigate();

  // onChange function storing data into state
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = useState("");

  const siteDetails = sessionStorage.getItem("siteDetails");
  const siteOrg = JSON.parse(siteDetails);

  const formik = useFormik({
    initialValues: {
      username: "",
      org_id: siteOrg?.map((e) => e.org_id)[0],
      site_id: siteOrg?.map((e) => e.site_id)[0],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setError(null);
        setLoader(true);
        await authService.reset(values);
        setSuccess(
          "Email with activation link sent your registered email address"
        );
        setTimeout(function () {
          navigate("/app/login", { replace: true });
        }, 3000);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      }
    },
  });

  return (
    <ThemeProvider theme={Theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={2}
          md={4}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "4px 0 39px 3px #00000025",
          }}
        />

        <Grid item xs={0} sm={2} md={2} />

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          square
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 10,
            }}
          >
            {error ? (
              <Alert
                severity="error"
                sx={{ mb: 2, border: "1px solid #f0625f" }}
              >
                {error}
              </Alert>
            ) : (
              <></>
            )}
            {success ? (
              <Alert
                severity="success"
                sx={{ mb: 2, border: "1px solid green" }}
              >
                {success}
              </Alert>
            ) : (
              <></>
            )}

            <img
              alt="logo"
              src={logo}
              className="img-fluid"
              style={{ marginBottom: "50px", height: "55px" }}
            />

            <Link href="/app/login" underline="none">
              <Typography
                component={"h1"}
                variant={"h5"}
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#615586",
                  textAlign: "left",
                  mb: 5,
                }}
              >
                <ChevronLeftIcon
                  sx={{ fontSize: "2em", marginLeft: "-50px" }}
                />
                Reset Password
              </Typography>
            </Link>

            <Typography
              component={"p"}
              variant={"p"}
              sx={{
                fontSize: "14px",
                fontWeight: "auto",
                color: "black",
                textAlign: "left",
                mb: 5,
              }}
            >
              Enter your username and we’ll send you a link to get back into
              your account.
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <CustomLoader open={loader} />

              <TextField
                margin="normal"
                fullWidth
                label="Username"
                name="username"
                id="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
                sx={{
                  mb: 5,
                }}
              />

              <TextField
                type="hidden"
                name="org_id"
                id="org_id"
                value={formik.values.org}
                onChange={formik.handleChange}
                sx={{
                  display: "none",
                }}
              />

              <TextField
                type="hidden"
                name="site_id"
                id="site_id"
                value={formik.values.site}
                onChange={formik.handleChange}
                sx={{
                  display: "none",
                }}
              />

              <Button
                type="Submit"
                color={"primaryColor"}
                fullWidth
                variant="contained"
                sx={{ mt: 8, mb: 2, height: "40px" }}
                disabled={success}
              >
                Reset password
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Forgotpassword;
