import { useState } from "react";
import HeaderContext from "./headerContext";

const HeaderState = ({children}) => {

  const patientId = localStorage.getItem("patientId");
  const patientName = localStorage.getItem("patientName");
  const status = localStorage.getItem("status");


  const patient = sessionStorage.getItem("pdt");
  const pateientD = JSON.parse(patient);


  const [name, setName] = useState(patientName);

  const [patientDetails, setPatientDetails] = useState(pateientD);

  const [appointment, setAppointment] = useState(null);

  const [sessionHistory, setSessionHistory] = useState(null);

  const [checkStatus, setCheckStatus] = useState(status)

  const [startSession, setStartSession] = useState(false)

  const [isEditable, setIsEditable] = useState(null)

  const [form, setForm] = useState([])

  const [finalData, setfinalData] = useState([])

  const [popUpOpen, setPopUpOpen] = useState(false)

  const [diagnosticPopup, setDiagnosticPopup] = useState(false);

  return ( 
    <HeaderContext.Provider value={{diagnosticPopup, setDiagnosticPopup, finalData, setfinalData, popUpOpen, setPopUpOpen, form, setForm, setName, name, patientDetails, setPatientDetails, setAppointment, appointment, setSessionHistory, sessionHistory, setCheckStatus, checkStatus, setStartSession, startSession, setIsEditable, isEditable}}>
        {children}
    </HeaderContext.Provider>
  );
};

export default HeaderState;
