import timestampHandler from "../utils/timstampHandler";

const siteTableColumns = [
  {
    text: "Site Name",
    dataField: "site_name",
  },
  {
    text: "Location ID",
    dataField: "location_id",
  },
  {
    text: "Business Name",
    dataField: "business_name",
  },
  {
    text: "CIN",
    dataField: "cin_number",
    formatter: (value) => {
      return value ? value : "N/A";
    },
  },
  {
    text: "GSTIN",
    dataField: "gstin_number",
    formatter: (value) => {
      return value ? value : "N/A";
    },
  },
  {
    text: "Phone Number",
    dataField: "work_phone",
  },
  {
    text: "Email",
    dataField: "work_email",
  },

  {
    text: "Active",
    dataField: "active",
    formatter: (value) => {
      return value ? "Yes" : "No";
    },
  },

  {
    dataField: "action",
    text: "Action",
  },
];

const idMasterSiteColumn = [
  {
    text: "Site Name",
    dataField: "site_name",
  },
  {
    text: "Business Name",
    dataField: "business_name",
  },
  {
    text: "Patient ref number",
    dataField: "id_master",
    formatter: (value) => {
      let item = value.find((a) => a.name === "Patient ref number");
      if (item) {
        return item.next_value - item.start_value;
      }
      return "Not added";
    },
  },
  {
    text: "Test order ref id",
    dataField: "id_master",
    formatter: (value) => {
      let item = value.find((a) => a.name === "Test order ref id");
      if (item) {
        return item.next_value - item.start_value;
      }
      return "Not added";
    },
  },
  {
    text: "Prescription No",
    dataField: "id_master",
    formatter: (value) => {
      let item = value.find((a) => a.name === "medication_script_id");
      if (item) {
        return item.next_value - item.start_value;
      }
      return "Not added";
    },
  },
  {
    text: "Invoice Number",
    dataField: "id_master",
    formatter: (value) => {
      let item = value.find((a) => a.name === "invoice_number");
      if (item) {
        return item.next_value - item.start_value;
      }
      return "Not added";
    },
  },
  {
    text: "Adjusted invoice number",
    dataField: "id_master",
    formatter: (value) => {
      console.log(value);

      let item = value.find((a) => a.name === "adjusted_invoice_number");
      if (item) {
        return item.next_value - item.start_value;
      }
      return "Not added";
    },
  },
  {
    dataField: "action",
    text: "Action",
  },
];

export default {
  siteTableColumns,
  idMasterSiteColumn,
};
