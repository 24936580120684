import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./SmsSubscription.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import RadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import SearchSite from "../../UI/SearchSite/SearchSite";
import moment from "moment";

export default function AddRole() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const [listSite, setListSite] = useState(null);
  const [listSiteError, setListSiteError] = useState(null);
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [site, setSite] = useState(null);
  const [siteError, setSiteError] = useState(null);
  const [paidRole, setPaidRole] = useState(null);
  const [paidRoleError, setPaidRoleError] = useState(null);
  const [duplicateToSite, setDuplicateToSite] = useState(null);
  const [duplicateToSiteError, setDuplicateToSiteError] = useState(null);

  const listSiteHandler = (e) => {
    setListSiteError(null);
    setListSite((prev) => {
      if (prev === null || (prev !== true && prev !== false)) {
        let val = e.target.value.toLowerCase() === "true";
        return val;
      }
      return !prev;
    });
  };
  const listSiteValidation = () => {
    if (listSite !== true && listSite !== false) {
      setListSiteError("Required");
      return false;
    }
    return true;
  };

  const nameHandler = (e) => {
    setNameError(null);
    setName(e.target.value);
  };

  const nameValidation = () => {
    if (!name) {
      setNameError("Required");
      return false;
    }
    return true;
  };
  const siteValidation = (msg) => {
    if (msg) {
      setSiteError(msg);
      return false;
    }
    if (!site) {
      setSiteError("Required");
      return false;
    }
    return true;
  };

  const selectedSite = (value) => {
    setSite(value);
  };

  const paidRoleHandler = (e) => {
    setPaidRoleError(null);
    setPaidRole((prev) => {
      if (prev === null || (prev !== true && prev !== false)) {
        let val = e.target.value.toLowerCase() === "true";
        return val;
      }
      return !prev;
    });
  };

  const paidRoleValidation = (e) => {
    if (paidRole !== true && paidRole !== false) {
      setPaidRoleError("Required");
      return false;
    }
    return true;
  };

  const duplicateHandler = (e) => {
    setDuplicateToSiteError(null);
    setDuplicateToSite((prev) => {
      if (prev === null || (prev !== true && prev !== false)) {
        let val = e.target.value.toLowerCase() === "true";
        return val;
      }
      return !prev;
    });
  };

  const duplicateValidation = (e) => {
    console.log(duplicateToSite);
    
    if (duplicateToSite !== true && duplicateToSite !== false) {
      setDuplicateToSiteError("Required");
      return false;
    }
    return true;
  };

  async function postData() {
    let body = {
      list_for_site: listSite,
      name,
      paid_role: paidRole,
      duplicate_to_site:duplicateToSite
    };

    const result = await axios
      .post(
        `${backendUrl}/api/role`,
        body,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/roles-management`, {
          state: {
            showAlert: "Roles has been added successfully",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        if (e.response?.data?.message?.includes("404")) {
          setError("ListSite/Name you entered is incorrect");
          return;
        }
        setError("Something went wrong. Please try again", true);
      });
  }

  const submitValidation = () => {
    let proceed = true;
    proceed = proceed && listSiteValidation();
    proceed = proceed && nameValidation();
    proceed = proceed && paidRoleValidation();
    proceed = proceed && duplicateValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      listSiteValidation();
      nameValidation();
      paidRoleValidation();
      duplicateValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/roles-management",
      iconName: "eos-icons:role-binding",
      name: "Roles Management",
    },
  ];

  const booleanOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      <PageHeader left="Add Role" />
      <Loader open={loader} />

      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            {/* First Column */}
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Name"
                onChangeAction={nameHandler}
                value={name}
                onBlurAction={nameValidation}
                error={nameError}
                placeholder="Name"
                required
              />
              <RadioGroup
                options={booleanOptions}
                onChangeAction={listSiteHandler}
                onBlur={listSiteValidation}
                name="list_site"
                value={listSite}
                label="List for Site?"
                error={listSiteError}
                md={6}
                required
              />
            </Grid>

            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              className={classes.Max}
              sx={{
                marginLeft: widthIs1630 ? "" : "80px",
                maxWidth: widthIs14 ? "" : "590px !important",
              }}
            >
              <RadioGroup
                options={booleanOptions}
                onChangeAction={paidRoleHandler}
                onBlur={paidRoleValidation}
                name="paid_role"
                value={paidRole}
                label="Paid Role?"
                error={paidRoleError}
                md={6}
                required
              />
              <RadioGroup
                options={booleanOptions}
                onChangeAction={duplicateHandler}
                onBlur={duplicateValidation}
                name="duplicate_to_site"
                value={duplicateToSite}
                label="Use permission from site?"
                error={duplicateToSiteError}
                md={6}
                required
              />
            </Grid>
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Submit"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
