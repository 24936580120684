import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Link,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { CssBaseline } from "@material-ui/core";
import Image from "./../../../assets/Images/login-side.png";
import { ThemeProvider } from "react-bootstrap";
import logo from "./../../../assets/Images/DarkLogo.png";
import Theme from "../../Theme/Theme";
import Alert from "@mui/material/Alert";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth/authState";
import { loginUser } from "../../context/auth/authReducer";
import storageService from "../../utils/localStorageHelpers";
import CustomLoader from "../../UI/Loader/Loader";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import {
  savePrivilages,
  setCallMimsEula,
  setCheckDeviceExpiry,
} from "./../../../redux/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import classes from "./Login.module.css"

const validationSchema = yup.object({
  username: yup.string("Enter your username").required("Required"),
  password: yup.string("Enter your password").required("Required"),
});

const Login = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  // onChange function storing data into state
  const [errorAlert, setErrorAlert] = useState(false);
  const [error, setError] = useState("");
  const [, authDispatch] = useAuthContext();
  const [loader, setLoader] = React.useState(false);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    return () => {};
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setLoader(true);
      let body = {
        ...values,
      };

      body = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        "secret key usr2323"
      ).toString();

      await axios({
        method: "POST",
        data: { data: body },
        withCredentials: true,
        url: `${backendUrl}/api/login`,
      })
        .then(async function (response) {
          let loginData;
          if (response?.data) {
            var bytes = CryptoJS.AES.decrypt(
              response?.data?.key,
              "secret key usr2323"
            );
            loginData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          }
          const permissions = loginData?.role?.permission;
          var ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(permissions),
            "secret key 123"
          ).toString();
          storageService.savePermission(ciphertext);
          dispatch(savePrivilages(permissions));

          let dt = { user: loginData };
          authDispatch(loginUser(dt));
          storageService.saveUser(dt);
          setLoader(false);
          dispatch(setCheckDeviceExpiry(true));
          dispatch(setCallMimsEula(true));
          navigate("/app/dashboard", {
            replace: true,
            checkDeviceExpiry: true,
            state: { fromLogin: true },
          });
        })
        .catch(function (error) {
          setLoader(false);
          if (error?.response?.data?.message.includes("incorrect")) {
            setError(error.response.data.message);
            setErrorAlert(error.response.data.message);
            return;
          }
          setError("Something went wrong. Please try again");
          setErrorAlert("Something went wrong. Please try again");
        });
    },
  });

  return (
    <ThemeProvider theme={Theme}>
      <Grid container className={classes.loginMain} component="main" sx={{ height: "100vh" }}>
      {location?.state?.sessionExpired ? (
            <Alert
              className={classes.idleError}
              severity="error"
              sx={{
                background: "#f8ab02",
                border: "#795200 1px solid",
                mb: 4,
                color: "#000",
              }}
            >
              Your session has expired. Please login again to continue
            </Alert>
          ) : (
            <></>
          )}
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={2}
          md={4}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "4px 0 39px 3px #00000025",
          }}
        />
        <Grid item xs={0} sm={2} md={2} />

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          square
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 10,
            }}
          >
            {errorAlert ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            ) : (
              <></>
            )}

            <img
              alt="logo"
              src={logo}
              className="img-fluid"
              style={{ marginBottom: "50px", height: "55px" }}
            />
            <Typography
              component={"h1"}
              variant={"h5"}
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#615586",
                textAlign: "left",
                mb: 5,
              }}
            >
              Login
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <CustomLoader open={loader} />

              <TextField
                margin="normal"
                fullWidth
                autoFocus
                sx={{
                  mb: 5,
                }}
                id="username"
                name="username"
                label="Username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />

              <TextField
                margin="normal"
                fullWidth
                sx={{
                  mb: 5,
                }}
                id="password"
                name="password"
                label="Password"
                type={showPass ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={() => setShowPass((prev) => !prev)}
                      >
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={formik.touched.password && formik.errors.password}
              />

              <Link href="/app/forgotpassword" variant="body2" underline="none">
                Forgot Password?
              </Link>

              <Button
                type="Submit"
                color={"primaryColor"}
                fullWidth
                variant="contained"
                sx={{ mt: 8, mb: 2, height: "40px" }}
              >
                Login
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
