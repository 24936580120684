import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../utils/CautionAlert";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Loader from "../../UI/Loader/Loader";
import { checkPrivilage } from "../../utils/checkPrivilage";
import columns from "../../Resources/mimsSubColumns";
import Theme from "../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";
import UploadTemplate from "./UploadTemplate";

const PrescriptionTemplates = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [switchItem, setSwitchItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [openAddTemplate, setOpenAddTemplate] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/prescription_templates`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data?.data) {
          var dt = response.data?.data;
          dt = dt.map((item) => {
            return {
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                          .ActionButton button {
                            background-color:${Theme.palette.primaryColor.backgroundColor};
                          }
                          .ActionButton button:hover {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                          .ActionButton button:focus {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                      `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                    disabled={item.status !== "Active"}
                  >
                    <ActionButton
                      content="Deactivate"
                      onClickAction={() => setDeleteItem(item)}
                      icon="ci:stop-sign"
                      eventKey="1"
                      disabled={item.status !== "Active"}
                    />
                  </DropdownButton>
                </Fragment>
              ),
            };
          });

          setData(dt);
          setLoader(false);
        } else {
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const successHandler = (msg) => {
    setSuccess(msg)
    loadData()
  }

  const confirmActivate = () => {
    activationHandler(switchItem);
    setSwitchItem(null);
  };

  const activationHandler = async (item) => {
    setLoader(true);
    await axios({
      method: "PATCH",
      withCredentials: true,
      data: { active: !item.active },
      url: `${backendUrl}/api/site/${item.id}`,
    })
      .then(() => {
        setLoader(false);
        setSuccess(
          `Site has been ${
            item.active ? "deactivated" : "activated"
          } successfully`
        );
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/mims-management",
      iconName: "fa6-solid:capsules",
      name: "Medication Management",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  const handleDelete = async (id) => {
    setDeleteItem(null);
    setLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/prescription_template/${id}`,
    })
      .then((response) => {
        setLoader(false);
        setSuccess("Prescription template has been successfully deleted");
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  return (
    <Fragment>
      <Loader open={loader} />
      {/* <CautionAlert
        severity="error"
        open={open}
        content="Are you sure you want to delete this item?"
        onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />
       */}
      <UploadTemplate
        open={openAddTemplate}
        handlePopupClose={() => setOpenAddTemplate(false)}
        successHandler={successHandler}
      />
      {deleteItem && (
        <CautionAlert
          severity="error"
          open={deleteItem}
          content={`Are you sure you want to delete this template?`}
          onCloseActionB={() => handleDelete(deleteItem?.id)}
          onCloseActionA={() => setDeleteItem(null)}
          okText="Yes"
          cancelText="No"
        />
      )}
      {error ? (
        <>
          <CautionAlert
            severity="error"
            open={error}
            content={error}
            oneButton={true}
            onCloseActionB={() => {
              setError(null);
            }}
            okText="Ok"
          />
        </>
      ) : null}
      <CautionAlert
        severity="error"
        open={apiError}
        content={apiError}
        onCloseActionB={retry}
        onCloseActionA={() => setApiError(null)}
        okText="Retry"
        cancelText="Dismiss"
      />
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/mims/subscriptions", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}
      <Breadcrumb options={breadCrumbData} />
      {showTable ? (
        <>
          <PageHeader
            left={"Prescription Templates"}
            right={
              !data.find((a) => a.status === "Active") && (
                <Button
                  fontSize="14px"
                  text="Add Prescription Template"
                  onClickAction={() => setOpenAddTemplate(true)}
                  startIcon={
                    <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
                  }
                ></Button>
              )
            }
          />

          {data && (
            <DataTable
              data={data}
              columns={columns.prescriptionTemplateColumns}
            />
          )}
        </>
      ) : null}
    </Fragment>
  );
};

export default PrescriptionTemplates;
