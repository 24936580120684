import React, { Fragment } from "react";
import Tile from "../../UI/Tile/Tile";
import { Col, Row } from "react-bootstrap";

// Import these files for breadcrumbs
import BreadStyles from "../../Layout/Breadcrumb/Breadcrumb.module.css";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import { useAuthContext } from "../../context/auth/authState";
import { checkAnyPrivilage } from "../../utils/checkPrivilage";

const Management = () => {
  const isFeesPrivilage = checkAnyPrivilage("PRIVATE_FEES");
  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
  ];

  return (
    <Fragment>
      <Breadcrumb options={breadCrumbData} />

      <Col lg={12}>
        <Row>
          <Col lg={3} className="mb-4">
            <Tile
              icon="bx:buildings"
              to="/app/organisations-management"
              text="Organisations"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="bi:building"
              to="/app/sites-management"
              text="Sites Management"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="cil:filter"
              to="/app/appointment-type"
              text="Appointment Types"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="fa-solid:sms"
              to="/app/sms-subscription"
              text="SMS Subscription"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="mi:email"
              to="/app/email-subscription"
              text="Email Subscription"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="mdi:shield-user"
              to="/app/user-subscription"
              text="User Subscription"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="mdi:sign"
              to="/app/e-sign-subscription"
              text="E-Sign Subscription"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="fa6-solid:capsules"
              to="/app/mims-management"
              text="Medication Management"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="eos-icons:role-binding"
              to="/app/roles-management"
              text="Roles Management"
            />
          </Col>

          <Col lg={3} className="mb-4">
            <Tile
              icon="medical-icon:immunizations"
              to="/app/immunisation"
              text="Immunisation Management"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="medical-icon:i-care-staff-area"
              to="/app/tests"
              text="Tests Management"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="emojione-monotone:id-button"
              to="/app/id-master"
              text="Id Master"
            />
          </Col>
          {/* <Col lg={3} className="mb-4">
            <Tile
              icon="bi:currency-dollar"
              to="/app/fee-management"
              text="Fee Management"
            />
          </Col>
          <Col lg={3} className="mb-4">
            <Tile
              icon="clarity:error-solid"
              to="/app/proda-exceptions"
              text="Medicare/DVA Exceptions"
            />
          </Col> */}
        </Row>
      </Col>
    </Fragment>
  );
};

export default Management;
