import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import classes from "./CreateAppointment.module.css";
import theme from "../../Theme/Theme";
import { ThemeProvider } from "react-bootstrap";
import Button from "../../UI/Button/Button";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import ViewAppointmentComp from "./ViewAppointment";
import { duration } from "moment";
import Loader from "../../UI/Loader/Loader";
import OtpInput from "../../UI/FormGroup/DurationInput/DurationInput";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";

// import { searchHiNumber } from "../../../healthCare/searchHPII";

const EditAppointment = (props) => {
  const appointmentType = useRef();
  const middleName = useRef();

  const siteName = JSON.parse(sessionStorage.getItem("siteDetails"));

  const getSite = siteName[0].site_name;

  let navigate = useNavigate();

  var data = props.appointments;

  var dataId = props.passIdAppointment;

  const [typeError, setTypeError] = useState(null);

  const [errorAlert, setErrorAlert] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [success, setSuccess] = useState("");

  const [hourError, setHourError] = useState(null);
  const [minuteError, setMinuteError] = useState(null);

  let splitTime = data.duration;
  const splittedTime = splitTime.split(":");

  const [durationError, setDurationError] = useState(null);

  const [type, setType] = useState(null);
  const [duration, setDuration] = useState(null);
  const [description, setDescription] = useState(null);

  const [hourMinute, setHourMinute] = useState({
    hour: splittedTime[0],
    minute: splittedTime[1],
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setType(data.appointment_type);
    setDuration(`${hourMinute.hour}:${hourMinute.minute}`);
    setDescription(data.description);
  };

  // const [form, setform] = useState({
  //   type: null,
  //   duration: `${hourMinute.hour}:${hourMinute.minute}`,
  //   description: data.description,
  // });

  const typeHandler = (e) => {
    setType(e.target.value);
  };

  const durationHandler = (e) => {
    setDuration(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  // function handleChange(event) {
  //   let value = event.target.value;
  //   let name = event.target.name;

  //   setform((prevalue) => {
  //     return {
  //       ...prevalue,
  //       [name]: value,
  //     };
  //   });
  // }

  const hourHandleChange = (hour) => {
    setDurationError("");
    if (hour === "") {
      setHourMinute((prevalue) => {
        return { ...prevalue, hour: "" };
      });
    }

    if (/^\d+$/.test(hour)) {
      let result = hour;

      let resultInt = Math.max(0, Math.min(24, Number(hour)));

      if (resultInt === 24) {
        result = resultInt.toString();
      }

      setHourMinute((prevalue) => {
        return { ...prevalue, hour: result };
      });
    } else {
      setHourMinute((prev) => prev);
      return;
    }
  };

  const minuteHandleChange = (e) => {
    setDurationError("");
    if (e.target.value === "") {
      setHourMinute((prevalue) => {
        return { ...prevalue, minute: "" };
      });
    }
    if (/^\d+$/.test(e.target.value)) {
      let result = e.target.value;
      let resultInt = Math.max(0, Math.min(59, Number(e.target.value)));
      if (resultInt === 59) {
        result = resultInt.toString();
      }
      setHourMinute((prevalue) => {
        return { ...prevalue, minute: result };
      });
    } else {
      setHourMinute((prev) => prev);
      return;
    }
  };

  const typeValidation = (e) => {
    if (e && e.target) {
      setType((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      });
    }
    if (!type) {
      setTypeError("Required");
    }
  };

  const hourValidation = (e) => {
    if (hourMinute.hour === "" || hourMinute.hour === null) {
      setHourMinute((prevalue) => {
        return {
          ...prevalue,
          hour: "00",
        };
      });
    }
    if (e && e.target) {
      setHourMinute((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      });
    }
    if (hourMinute.hour === "00" && hourMinute.minute === "00") {
      setDurationError("Required");
    }
  };

  const minuteValidation = (e) => {
    if (hourMinute.minute === "" || hourMinute.minute === null) {
      setHourMinute((prevalue) => {
        return {
          ...prevalue,
          minute: "00",
        };
      });
    }
    if (e && e.target) {
      setHourMinute((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      });
    }
    if (hourMinute.hour === "00" && hourMinute.minute === "00") {
      setDurationError("Required");
    }
  };

  const editData = async () => {
    if (
      type === data.appointment_type &&
      description === data.description &&
      `${hourMinute.hour}:${hourMinute.minute}` === data.duration
    ) {
      setError("You've made no changes!");
      setErrorAlert("You've made no changes!");
    } else {
      if (type || description || hourMinute.hour || hourMinute.minute) {
        setLoader(true);
        await axios({
          method: "PATCH",
          data: {
            appointment_type: `${type}`,
            description: `${description}`,
            duration: `${hourMinute.hour}:${hourMinute.minute}`,
          },
          withCredentials: true,
          url: `${backendUrl}/${getSite}/api/appointment_types/${dataId.id}`,
        })
          .then((response) => {
            // setSuccessAlert(true);
            // setSuccess(response.data.message);
            setLoader(false);
            setErrorAlert(false);
            navigate("/app/appointment-type/", {
              state: {
                showAlert: "Your Changes has been successfully updated",
              },
            });
            setTimeout(() => {
              props.reload();
            }, 2000);
          })
          .catch(function (error) {
            setLoader(false);
            if (error.response) {
              setError(error.response.data.message);
              setErrorAlert(error.response.data.message);
              
            } else {
              setError(error.message);
              setErrorAlert(error.message);
              
            }
          });
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {errorAlert ? (
        <Alert severity="error" sx={{ mb: 2, border: "1px solid #f0625f" }}>
          {error}
        </Alert>
      ) : null}
      {successAlert ? (
        <Alert severity="success" sx={{ mb: 2, border: "1px solid green" }}>
          {success}
        </Alert>
      ) : null}
      <PageHeader left="Edit appointment type" />
      <form>
        <Box className={classes.FormBox}>
          <Grid container Spacing={3}>
            <TextBox
              label="Appointment Type"
              name="type"
              onChangeAction={typeHandler}
              ref={appointmentType}
              value={type}
              required
            />

            <Loader open={loader} />

            <OtpInput
              md={6}
              label="Duration"
              duration={hourMinute}
              hourChange={hourHandleChange}
              error={durationError}
            />

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={6}
              spacing={2}
              className={classes.FormGroup}
            >
              <Grid container spacing={2}>
                <Grid item xs={4} className={`LabelBox`}>
                  <label>Descriptiondddd</label>
                </Grid>
                <Grid item xs={8}>
                  <textarea
                    className="InputPrimary"
                    name="description"
                    onChange={descriptionHandler}
                    // defaultValue={data.description}
                    // placeholder={data.description}
                    ref={middleName}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  display: "inline-flex",
                  m: 1,
                }}
              >
                <Button text="Update" onClickAction={editData} />
              </Box>

              <Box
                sx={{
                  display: "inline-flex",
                  m: 1,
                }}
              >
                <Button
                  onMouseDownAction={props.onClickAction}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
};

export default EditAppointment;
