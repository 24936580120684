import { Alert, Backdrop } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import DataView from "../../Layout/DataView/DataView";
import Button from "../../UI/Button/Button";
import columns from "../../Resources/siteDetailsColumns";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import CautionAlert from "../../utils/CautionAlert";
import storageService from "./../../utils/localStorageHelpers";
import Loader from "../../UI/Loader/Loader";
import { checkPrivilage } from "../../utils/checkPrivilage";

export default function ViewSiteDetails() {
  const alertRef = useRef(null);

  const canUpdate = checkPrivilage("SITES", "UPDATE");

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { id } = useParams();

  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadSite();
  }, []);

  const loadSite = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/all/sites?id=${id}&include_guest=true`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data?.data) {
          var dt = response.data?.data[0];
          var logo = response.data?.data[1];
          var tempLogo = response.data?.data[2];
          setData({ ...dt, ...logo, ...tempLogo });

          setLoader(false);
        } else {
          errorHandler("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        errorHandler("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  console.log(data);
  

  const errorHandler = (msg, autoHidden) => {
    setError(msg);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/sites-management",
      iconName: "bi:building",
      name: "Site Details",
    },
  ];

  const retry = () => {
    setError(null);
    setTimeout(() => {
      loadSite();
    }, 100);
  };

  return (
    <Fragment>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />

      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate(pathname, location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}

      {error ? (
        <>
          <Alert
            severity="error"
            sx={{
              mb: 2,
              border: "1px solid red",
              zIndex: "99999 !important",
              position: "relative",
            }}
            action={
              <>
                <Button
                  onClickAction={retry}
                  color="inherit"
                  size="small"
                  text="Retry"
                />
                <Button
                  onClickAction={() => setError(null)}
                  color="inherit"
                  size="small"
                  text="Dismiss"
                  margin="0px 10px !important"
                />
              </>
            }
          >
            {error}
          </Alert>
          <Backdrop
            sx={{ backgroundColor: "#00000000", zIndex: 500 }}
            open={error}
          ></Backdrop>
        </>
      ) : null}

      {/* <img width="50px" src={`/api/sites/${value}`} alt="logo"></img> */}

      <DataView
          xs={12}
          sm={6}
          md={4}
          lg={3}
        data={data}
        columns={columns.viewSiteDetailsColumns}
        title={`View Site Details`}
        edit={true}
        editView={() =>
          navigate(`/app/site/edit/${data.id}`, { state: { data: data } })
        }
        closeView={() => navigate("/app/sites-management")}
      />
    </Fragment>
  );
}
